.notifications-editor {
  max-width: 600px;
  margin: 0 auto;
}

.notifications-editor .form-group {
  margin-bottom: 20px;
}

.notifications-editor label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.notifications-editor select,
.notifications-editor input,
.notifications-editor textarea {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.notifications-editor textarea {
  resize: vertical;
  min-height: 100px;
}

.notifications-editor .send-button {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.notifications-editor .send-button:hover {
  background-color: #2980b9;
}

.notifications-editor .send-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.notifications-editor .send-button:disabled:hover {
  background-color: #ccc;
}

.success-alert {
  background-color: #d4edda;
  color: #155724;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

.notification-preview {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 4px;
  margin: 15px 0;
}

.notification-preview p {
  margin: 8px 0;
}

/* Dropdown styles */
.custom-dropdown {
  position: relative;
  width: 100%;
}

.dropdown-header {
  padding: 10px 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.dropdown-header:hover {
  border-color: #b3b3b3;
}

.dropdown-header:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

.dropdown-arrow {
  font-size: 12px;
  color: #666;
  transition: transform 0.2s;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
}

.filter-container {
  position: relative;
}

.recipient-filter {
  width: 100%;
  padding: 12px;
  border: none;
  font-size: 14px;
  background: white;
  box-sizing: border-box;
  color: #000;
  border-bottom: 1px solid #eee;
}

.recipient-filter:focus {
  outline: none;
  background-color: #fff;
}

.dropdown-section-header {
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 600;
  color: #666;
  background-color: #f8f9fa;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-count {
  font-size: 11px;
  color: #999;
  background-color: #eee;
  padding: 2px 6px;
  border-radius: 10px;
}

.notifications-editor .dropdown-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 14px 0px 24px !important;
  cursor: pointer;
  transition: background-color 0.2s;
  min-height: 36px;
}

.notifications-editor .dropdown-option-content {
  flex: 1;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.edit-topic-button {
  flex-shrink: 0;
  padding: 2px 6px;
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 14px;
  opacity: 0.6;
  transition: opacity 0.2s;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -4px 0;
}

.edit-topic-button:hover {
  opacity: 1;
}

.dropdown-option:hover .edit-topic-button {
  opacity: 1;
}

.dropdown-option:hover {
  background-color: #f5f5f5;
}

.dropdown-option.selected {
  background-color: #e3f2fd;
  color: #1976d2;
  font-weight: 500;
}

.dropdown-no-results {
  padding: 16px;
  text-align: center;
  color: #666;
  background-color: #f8f9fa;
}

.create-topic-button {
  margin-top: 8px;
  padding: 8px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.create-topic-button:hover {
  background-color: #2980b9;
}

.topic-actions {
  display: flex;
  gap: 8px;
  align-items: center;
}

.success-message {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4CAF50;
  color: white;
  padding: 12px 24px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  animation: fadeIn 0.3s ease-in-out;
  max-width: 80vw;
  word-break: break-all;
  font-size: 14px;
  line-height: 1.4;
  z-index: 1000;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} 