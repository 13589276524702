.map-item {
  margin-bottom: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.map-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: background-color 0.2s;
}

.map-header:hover {
  background-color: #e9ecef;
}

.map-header.expanded {
  background-color: #e9ecef;
  border-bottom: 1px solid #ddd;
}

.map-header-content {
  display: flex;
  align-items: center;
  gap: 16px;
}

.map-header-content h3 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.map-coordinates-preview {
  color: #666;
  font-size: 14px;
  font-family: monospace;
}

.map-header-actions {
  display: flex;
  gap: 8px;
}

.map-content {
  padding: 16px;
  background-color: white;
}

.add-map-button {
  margin-bottom: 16px;
  padding: 8px 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-map-button:hover {
  background-color: #218838;
}

.remove-map-button {
  padding: 4px 8px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.remove-map-button:hover {
  background-color: #c82333;
}

.maps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.map-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.map-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.map-image {
  width: 100%;
  padding-top: 75%;
  position: relative;
  background: #f5f5f5;
}

.map-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.map-info {
  padding: 16px;
}

.map-info h3 {
  margin: 0 0 8px 0;
  font-size: 16px;
  color: #333;
}

.coordinates-preview {
  font-size: 12px;
  color: #666;
  font-family: monospace;
}

.add-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  border: 2px dashed #dee2e6;
  min-height: 200px;
}

.add-card span {
  font-size: 32px;
  color: #adb5bd;
  margin-bottom: 8px;
}

.add-card h3 {
  margin: 0;
  color: #adb5bd;
}

.map-edit-modal {
  width: 90%;
  max-width: 800px;
}

.map-edit-content {
  padding: 24px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.save-button {
  background: #28a745;
  color: white;
}

.save-button:disabled {
  background: #6c757d;
  cursor: not-allowed;
}

.cancel-button {
  background: #6c757d;
  color: white;
}

.delete-button {
  background: #dc3545;
  color: white;
  margin-left: auto;
}

/* Update the coordinates editing specific styles */
.coordinates-edit-view {
  width: 90vw;
  max-width: 1200px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
}

.coordinates-map-container {
  flex: 1;
  min-height: 500px;
  position: relative;
  margin: 20px 0;
  background: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
}

.coordinates-map-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.map-edit-modal.coordinates-mode {
  max-width: 1200px;
  width: 90vw;
  height: auto;
  min-height: 600px;
}

.map-edit-modal.coordinates-mode .modal-content {
  height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.coordinates-edit-view h3 {
  margin: 24px 24px 0;
}

.coordinates-edit-view .modal-actions {
  margin: 0 24px 24px;
} 