.shows-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  gap: 16px;
}

.filter-group {
  display: flex;
  gap: 16px;
  flex: 1;
}

.scene-filter {
  min-width: 200px;
}

.scene-filter select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  background-color: #f8f9fa;
  color: #495057;
  font-size: 14px;
  cursor: pointer;
}

.scene-filter select:hover {
  border-color: #adb5bd;
}

.scene-filter select:focus {
  outline: none;
  border-color: #0d6efd;
  box-shadow: 0 0 0 2px rgba(13,110,253,0.25);
}

.view-toggle {
  display: flex;
  gap: 2px;
  background: #f8f9fa;
  padding: 4px;
  border-radius: 8px;
}

.view-toggle button {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: none;
  color: #6c757d;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.view-toggle button:hover {
  color: #495057;
  background: rgba(0,0,0,0.05);
}

.view-toggle button.active {
  background: white;
  color: #0d6efd;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.add-show-button {
  position: fixed;
  bottom: 24px;
  right: 24px;
  padding: 12px 24px;
  background: #0d6efd;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(13,110,253,0.25);
  display: flex;
  align-items: center;
  gap: 8px;
}

.add-show-button:hover {
  background: #0b5ed7;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(13,110,253,0.3);
}

.add-show-button::before {
  content: '+';
  font-size: 18px;
  font-weight: 400;
  margin-right: 4px;
}

/* Modal styles */
.modal-content select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  background-color: #f8f9fa;
  color: #495057;
  font-size: 14px;
  margin-bottom: 16px;
  cursor: pointer;
}

.modal-content select:hover {
  border-color: #adb5bd;
}

.modal-content select:focus {
  outline: none;
  border-color: #0d6efd;
  box-shadow: 0 0 0 2px rgba(13,110,253,0.25);
}

.date-time-inputs {
  margin-bottom: 16px;
}

.date-time-inputs label {
  display: block;
  margin-bottom: 8px;
  color: #495057;
  font-weight: 500;
}

.date-time-inputs input {
  padding: 8px 12px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 8px;
  color: #495057;
  background-color: #f8f9fa;
}

.date-time-inputs input:hover {
  border-color: #adb5bd;
}

.date-time-inputs input:focus {
  outline: none;
  border-color: #0d6efd;
  box-shadow: 0 0 0 2px rgba(13,110,253,0.25);
}

.modal-actions {
  display: flex;
  gap: 8px;
  margin-top: 24px;
}

.modal-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.modal-actions button:first-child {
  background: #0d6efd;
  color: white;
}

.modal-actions button:first-child:hover {
  background: #0b5ed7;
}

.modal-actions button:nth-child(2) {
  background: #f8f9fa;
  color: #495057;
}

.modal-actions button:nth-child(2):hover {
  background: #e9ecef;
}

.modal-actions button:last-child {
  background: #dc3545;
  color: white;
  margin-left: auto;
}

.modal-actions button:last-child:hover {
  background: #bb2d3b;
}

.search-bar {
  flex: 1;
  min-width: 200px;
  max-width: 400px;
}

.search-bar input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 14px;
  background-color: #f8f9fa;
  color: #495057;
  box-sizing: border-box;
}

.search-bar input:hover {
  border-color: #adb5bd;
}

.search-bar input:focus {
  outline: none;
  border-color: #0d6efd;
  box-shadow: 0 0 0 2px rgba(13,110,253,0.25);
} 