.modal-description {
  margin: 16px 0;
  color: #666;
  line-height: 1.4;
  font-size: 14px;
}

.link-container {
  display: flex;
  margin: 24px 0;
  align-items: center;
}

.link-input {
  flex: 1;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  background: #fff;
  cursor: text;
  outline: none;
}

.link-input:focus {
  border-color: #3498db;
}

.copy-button {
  padding: 12px 32px !important;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
  transition: background-color 0.2s ease;
  font-weight: 400;
  margin-left: 16px !important;
  margin-top: 0px;
}

.copy-button:hover {
  background-color: #2980b9;
}

.copy-button:active {
  transform: translateY(1px);
}

.modal-content {
  max-width: 600px;
  width: 90%;
  padding: 24px;
}

.modal-content h3 {
  margin: 0;
  color: #333;
  font-size: 18px;
  margin-bottom: 8px;
} 