.admin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  padding: 20px;
}

.admin-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.admin-content h1 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.admin-menu {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.admin-button {
  display: block;
  padding: 12px;
  text-align: center;
  text-decoration: none;
  background-color: #3498db;
  color: white;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.admin-button:hover {
  background-color: #2980b9;
}

.admin-button.back {
  background-color: #e74c3c;
  margin-top: 20px;
}

.admin-button.back:hover {
  background-color: #c0392b;
} 