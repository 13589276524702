.unattributed-section {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  text-align: center;
  color: #6c757d;
}

.map-tabs {
  display: flex;
  gap: 4px;
  margin-bottom: 20px;
  padding: 8px;
  overflow-x: auto;
  background: #f8f9fa;
  border-radius: 12px;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;
}

.map-tabs::-webkit-scrollbar {
  height: 4px;
}

.map-tabs::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

.map-tabs::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

.map-tab {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  min-width: fit-content;
  font-weight: 500;
  color: #495057;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.map-tab:hover {
  background: #e9ecef;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.15);
}

.map-tab.active {
  background: #0d6efd;
  color: white;
  box-shadow: 0 2px 4px rgba(13,110,253,0.25);
}

.map-tab:last-child {
  background-color: white;
  color: #dc3545;
  border: 1px solid #dc3545;
}

.map-tab:last-child:hover {
  background-color: #dc3545;
  color: white;
}

.map-tab:last-child.active {
  background-color: #dc3545;
  color: white;
  box-shadow: 0 2px 4px rgba(220,53,69,0.25);
}

.map-container {
  position: relative;
  margin-bottom: 20px;
  width: 80%;
  margin: 0 auto;
  background-color: #f5f5f5;
  overflow: hidden;
  border: 1px solid #dee2e6;
  border-radius: 8px;
}

.map-placeholder {
  background-color: #f8f9fa;
  border: 2px dashed #dee2e6;
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  margin: 20px 0;
}

.map-placeholder p {
  color: #6c757d;
  margin-bottom: 16px;
}

.map-placeholder button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.map-placeholder button:hover {
  background-color: #0056b3;
}

.pois-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.poi-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.poi-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.card-content {
  padding: 15px;
}

.card-title {
  margin: 0 0 8px 0;
  font-size: 16px;
  color: #333;
}

.card-subtitle {
  font-size: 14px;
  color: #666;
  margin: 0;
}

.add-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  border: 2px dashed #dee2e6;
  min-height: 200px;
}

.add-card span {
  font-size: 32px;
  color: #adb5bd;
  margin-bottom: 8px;
}

.add-card h3 {
  margin: 0;
  color: #adb5bd;
}

.search-bar {
  margin-bottom: 20px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.search-bar input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

.modal-content select,
.form-field select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  background-color: #f8f9fa;
  color: #495057;
  font-size: 14px;
  margin-bottom: 16px;
  cursor: pointer;
}

.modal-content select:hover,
.form-field select:hover {
  border-color: #adb5bd;
}

.modal-content select:focus,
.form-field select:focus {
  outline: none;
  border-color: #0d6efd;
  box-shadow: 0 0 0 2px rgba(13,110,253,0.25);
}

.form-field {
  margin-bottom: 16px;
}

.form-field label {
  display: block;
  margin-bottom: 8px;
  color: #495057;
  font-weight: 500;
}

.poi-marker {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #dc3545;
  border: 2px solid white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: grab;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.poi-marker::after {
  content: '';
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 50%;
}

.poi-marker:hover {
  transform: translate(-50%, -50%) scale(1.1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

.poi-marker.dragging {
  cursor: grabbing;
  transform: translate(-50%, -50%) scale(1.15);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  background-color: #0d6efd;
}

.poi-marker.scene {
  background-color: #dc3545;
}

.poi-marker.food {
  background-color: #28a745;
} 