.deferrable-saving-component {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.deferrable-saving-component button {
    margin-top: 0px
}

.deferrable-saving-header {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 16px;
  background-color: #ffffff;
  border-bottom: 1px solid #e1e4e8;
}

.deferrable-saving-content {
  flex: 1;
  overflow: auto;
  padding: 20px;
}