.Login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
}

.login-form-wrapper {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-form h2 {
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.login-form button {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-sizing: border-box;
}

.login-form button[type="submit"] {
  margin-top: 15px;
  background-color: #4CAF50;
  color: white;
  position: relative;
}

.login-form button[type="submit"]:disabled:hover {
  background-color: #cccccc;
}

.login-form button[type="submit"]:hover {
  background-color: #45a049;
}

.create-festival-btn {
  background-color: #2196F3;
  color: white;
}

.create-festival-btn:hover {
  background-color: #1E88E5;
}

.error-message {
  color: #f44336;
  margin-top: 10px;
  text-align: center;
}

.login-form input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.login-form button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.login-form button.loading {
  position: relative;
  color: transparent;
}

.login-form button.loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  border-right-color: transparent;
  animation: button-loading-spinner 0.75s linear infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.reset-password-button {
  background-color: #ff9800 !important;
  color: white !important;
  margin-bottom: 15px !important;
}

.reset-password-button:hover {
  background-color: #f57c00 !important;
}

.reset-password-button:disabled {
  background-color: #cccccc !important;
}
