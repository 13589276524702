.pages-editor {
  padding: 20px;
}

.add-page-button {
  margin-bottom: 20px;
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pages-list {
  list-style: none;
  padding: 0;
}

.page-item {
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  position: relative;
}

.page-item-header {
  padding: 12px 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.2s;
}

.page-item-header:hover {
  background-color: #f5f5f5;
}

.page-item-preview {
  display: flex;
  align-items: center;
  gap: 12px;
}

.page-category {
  background-color: #e0e0e0;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

.page-item-edit {
  padding: 20px;
  border-top: 1px solid #ddd;
  position: relative;
  z-index: 1;
}

.page-item-edit input,
.page-item-edit textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.page-item-edit textarea {
  min-height: 200px;
}

.page-item-actions {
  display: flex;
  gap: 10px;
}

.page-item-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.page-item-actions button:first-child {
  background-color: #4CAF50;
  color: white;
}

.page-item-actions button:nth-child(2) {
  background-color: #f44336;
  color: white;
}

.error-message {
  color: #f44336;
  margin-bottom: 10px;
}

.category-input-container {
  position: relative;
}

.category-dropdown {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.category-option {
  padding: 10px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 14px;
}

.category-option:hover {
  background-color: #f0f0f0;
}

.category-option-empty {
  padding: 10px 12px;
  color: #666;
  font-style: italic;
  font-size: 14px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group:last-child {
  margin-bottom: 0;
}

.page-item-edit input:focus,
.page-item-edit textarea:focus {
  outline: none;
  border-color: #2196F3;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.1);
}

.form-row {
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
}

.form-group-half {
  flex: 1;
  min-width: 0;
}

.page-item-edit input[type="text"]::-webkit-inner-spin-button,
.page-item-edit input[type="text"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.page-item-edit input[type="text"] {
  -moz-appearance: textfield;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
  font-size: 14px;
}

.form-label-hint {
  display: block;
  font-weight: normal;
  font-size: 12px;
  color: #666;
  margin-top: 2px;
}

.page-icon {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
}

.page-icon-placeholder {
  width: 40px;
  height: 40px;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.page-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
 