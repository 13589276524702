.user-editor {
  padding: 20px;
}

.user-editor h2 {
  margin-bottom: 20px;
  color: #333;
}

.user-table-container {
  overflow-x: auto;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.user-table th,
.user-table td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.user-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #495057;
}

.user-table tr:last-child td {
  border-bottom: none;
}

.user-table tr:hover {
  background-color: #f8f9fa;
}

.role-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.role-tag {
  background-color: #e9ecef;
  color: #495057;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.role-editor {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  min-width: unset;
}

.role-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.role-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #495057;
}

.role-checkbox input[type="checkbox"] {
  margin: 0;
}

.update-roles-button {
  padding: 6px 12px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s;
}

.update-roles-button:hover:not(:disabled) {
  background-color: #2980b9;
}

.update-roles-button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.user-editor-loading {
  text-align: center;
  padding: 40px;
  color: #666;
}

.user-editor-error {
  color: #dc3545;
  padding: 20px;
  text-align: center;
  background-color: #f8d7da;
  border-radius: 8px;
  margin: 20px;
}

.current-user {
  background-color: #f8f9fa;
}

.current-user-note {
  color: #6c757d;
  font-style: italic;
  font-size: 14px;
}

.roles-dropdown {
  position: relative;
  width: 100%;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f9fa;
  cursor: pointer;
  min-height: 38px;
}

.dropdown-header.disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
}

.dropdown-header .placeholder {
  color: #6c757d;
}

.dropdown-arrow {
  color: #6c757d;
  font-size: 12px;
  margin-left: 8px;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 9999;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.dropdown-option {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #333;
  background-color: #ffffff;
}

.dropdown-option span {
  color: #333;
  font-size: 14px;
  margin-left: 8px;
}

.dropdown-option:hover {
  background-color: #f0f0f0;
}

.dropdown-option input[type="checkbox"] {
  margin: 0;
  width: auto;
}

.selected-roles {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  flex: 1;
}

.selected-roles .role-tags {
  margin: 0;
}

.role-editor {
  min-width: 250px;
}

.update-roles-button {
  width: 100%;
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.update-roles-button:hover:not(:disabled) {
  background-color: #2980b9;
  transform: translateY(-1px);
}

.update-roles-button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.current-user-roles {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.current-user-role {
  color: #495057;
  font-size: 14px;
  padding: 4px 0;
}

.user-table td {
  position: relative;
  overflow: visible;
}

.user-table {
  overflow: visible;
}

.user-table-container {
  overflow: visible;
}

.user-table th:nth-child(1),
.user-table td:nth-child(1) {
  width: 35%;  /* Email column */
}

.user-table th:nth-child(2),
.user-table td:nth-child(2) {
  width: 45%;  /* Roles column */
}

.user-table th:nth-child(3),
.user-table td:nth-child(3) {
  width: 20%;  /* Actions column */
}

.user-editor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.new-user-button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.new-user-button:hover {
  background-color: #45a049;
  transform: translateY(-1px);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content h3 {
  margin: 0 0 20px 0;
  color: #333;
}

.modal-form-group {
  margin-bottom: 20px;
  width: 100%;
}

.modal-form-group label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-weight: 500;
}

.modal-form-group input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.modal-form-group input.error {
  border-color: #dc3545;
  background-color: #fff8f8;
}

.input-error {
  color: #dc3545;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0;
}

.roles-dropdown {
  width: 100%;
  margin-bottom: 4px;
}

.modal-actions {
  display: flex;
  gap: 12px;
  margin-top: 24px;
}

.modal-submit {
  flex: 1;
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.modal-submit:hover:not(:disabled) {
  background-color: #45a049;
}

.modal-cancel {
  flex: 1;
  padding: 8px 16px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.modal-cancel:hover:not(:disabled) {
  background-color: #d32f2f;
}

.modal-error {
  color: #f44336;
  background-color: #ffebee;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 14px;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.modal-close {
  background: none;
  border: none;
  font-size: 24px;
  color: #666;
  cursor: pointer;
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.modal-close:hover {
  background-color: #f0f0f0;
  color: #333;
}

.input-hint {
  color: #666;
  font-size: 12px;
  margin-top: 4px;
}

.modal-roles-hint {
  color: #666;
  font-size: 12px;
  margin-bottom: 8px;
}

.modal-form {
  width: 100%;
}

.modal-form-group {
  margin-bottom: 20px;
  width: 100%;
}

.modal-form-group label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-weight: 500;
}

.modal-form-group input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.modal-form-group input.error {
  border-color: #dc3545;
  background-color: #fff8f8;
}

.input-error {
  color: #dc3545;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0;
}

.roles-dropdown {
  width: 100%;
  margin-bottom: 4px;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid #ffffff;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.modal-content {
  animation: modalSlideIn 0.3s ease;
}

@keyframes modalSlideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.modal-error, .input-error {
  color: #dc3545;
  font-size: 12px;
  margin-top: 4px;
  text-align: left;
  padding: 4px 0;
}

.modal-error {
  background-color: #ffebee;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 14px;
}

.modal-form-group input {
  text-align: left;
}

.delete-button {
  padding: 6px 12px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.2s ease;
}

.delete-button:hover {
  background-color: #c82333;
  transform: translateY(-1px);
}

.no-actions {
  color: #6c757d;
  font-style: italic;
  font-size: 14px;
}

.modal-delete {
  flex: 1;
  padding: 8px 16px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.modal-delete:hover:not(:disabled) {
  background-color: #c82333;
}

.user-actions {
  display: flex;
  gap: 8px;
}

.reset-password-button {
  padding: 6px 12px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.2s ease;
}

.reset-password-button:hover {
  background-color: #2980b9;
  transform: translateY(-1px);
} 