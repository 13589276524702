.infos-editor {
  margin: 0 auto;
}

.form-group {
  margin-bottom: 24px;
}

.form-group label {
  display: block;
  font-weight: 500;
  margin-bottom: 8px;
  color: #333;
}


.form-group input[type="number"],
.form-group select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: #3a3f4b;
}

.form-group input[type="number"] {
  max-width: 120px;
}

.number-input-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.number-input-wrapper .field-description {
  margin-top: 0;
  flex: 1;
}

.field-description {
  font-size: 14px;
  color: #666;
  margin-top: 4px;
}

.image-editors-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  width: 100%;
}

/* Theme Section Styles */
.theme-section {
  background-color: white;
  border: 1px solid #e1e4e8;
  border-radius: 8px;
  margin-top: 12px;
  overflow: hidden;
}

.theme-section h3 {
  margin: 0;
  padding: 16px 20px;
  color: #2c3e50;
  font-size: 18px;
  border-bottom: 1px solid #e1e4e8;
  background-color: #f8f9fa;
}

.theme-section-content {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
}

/* For image editors section, override the grid template */
.theme-section-content .image-editors-grid {
  grid-column: 1 / -1; /* Make it span all columns */
}

.theme-section-group {
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 16px;
}

.theme-section-group h4 {
  margin: 0 0 16px 0;
  color: #2c3e50;
  font-size: 16px;
  font-weight: 500;
}

.color-input-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 4px;
  transition: border-color 0.2s;
  width: fit-content;
  height: 40px;
}

.color-input-wrapper:hover {
  border-color: #bbb;
}

.color-input-wrapper:focus-within {
  border-color: #3498db;
}

.color-input-wrapper input[type="color"] {
  -webkit-appearance: none;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
}

.color-input-wrapper input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

.color-input-wrapper input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 4px;
}

.color-input-wrapper input[type="color"]::-moz-color-swatch {
  border: none;
  border-radius: 4px;
}

.color-input-wrapper input[type="text"] {
  width: 80px;
  border: none;
  padding: 0 8px;
  margin-bottom: 0px;
  font-family: ui-monospace, SFMono-Regular, SF Mono, Menlo, Consolas, Liberation Mono, monospace;
  font-size: 14px;
  text-transform: uppercase;
  background: transparent;
  color: #333;
  height: 100%;
  line-height: 40px;
}

.color-input-wrapper input[type="text"]:focus {
  outline: none;
}

/* Make the color input look better in Firefox */
@-moz-document url-prefix() {
  .color-input-wrapper input[type="color"] {
    padding: 0;
    border: none;
  }
}

/* Background Type Select */
select {
  color: white;
  appearance: none;
  background-color: white;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
  border: 1px solid #ddd;
  cursor: pointer;
}

select:focus {
  outline: none;
  border-color: #3498db;
}

/* Responsive Design */
@media (max-width: 600px) {
  .theme-section {
    border-radius: 0;
    margin: 0 -20px;
  }

  .theme-section-content {
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 16px;
  }

  .color-input-wrapper {
    flex-direction: row;
  }

  .color-input-wrapper input[type="color"] {
    width: 40px;
  }

  .color-input-wrapper input[type="text"] {
    max-width: none;
  }
} 