.reporting-editor {
  padding: 20px;
}

.reports-table-container {
  overflow-x: auto;
  margin-top: 20px;
}

.reports-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.reports-table th,
.reports-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.reports-table th {
  background-color: #f5f5f5;
  font-weight: 600;
  color: #333;
}

.reports-table tr:hover {
  background-color: #f8f9fa;
}

.reports-table td {
  color: #666;
  font-size: 14px;
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.status-badge.pending {
  background-color: #fff3cd;
  color: #856404;
}

.status-badge.ignored {
  background-color: #f8d7da;
  color: #721c24;
}

.status-badge.processed {
  background-color: #d4edda;
  color: #155724;
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.ignore-button,
.delete-button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.ignore-button {
  background-color: #6c757d;
  color: white;
}

.ignore-button:hover {
  background-color: #5a6268;
}

.delete-button {
  background-color: #dc3545;
  color: white;
}

.delete-button:hover {
  background-color: #c82333;
}

.modal-ignore {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.modal-ignore:hover:not(:disabled) {
  background-color: #5a6268;
}

.modal-delete {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.modal-delete:hover:not(:disabled) {
  background-color: #c82333;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
} 