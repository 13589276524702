.editor {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f4f6f8;
}

.editor-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  max-width: 100%;
}

.editor-header h1 {
  margin: 0;
  font-size: 20px;
  color: #333;
  flex-grow: 1;
  text-align: center;
}

.back-btn, .save-button {
  padding: 8px 16px;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.back-btn {
  background-color: #e74c3c;
  margin-right: 20px;
}

.back-btn:hover {
  background-color: #c0392b;
}

.save-status-container {
  display: flex;
  align-items: center;
}

.save-status {
  margin-right: 10px;
  font-size: 14px;
  color: #666;
}

.save-button {
  background-color: #3498db;
  border: none;
  cursor: pointer;
}

.save-button:hover {
  background-color: #2980b9;
}

.save-button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.editor-layout {
  display: flex;
  padding-top: 60px;
  height: calc(100vh - 60px);
  overflow: hidden;
}

.editor-menu {
  width: 200px;
  min-width: 200px;
  flex-shrink: 0;
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 20px 0;
}

.editor-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.editor-menu li {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.editor-menu li:hover {
  background-color: #34495e;
}

.editor-menu li.active {
  background-color: #3498db;
}

.editor-main {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  min-width: 0;
}

.editor-content {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 4px;
  margin-top: 20px;
}

/* Component-specific styles */
.infos-editor,
.news-editor,
.artists-editor,
.pois-editor,
.shows-editor {
  margin-bottom: 40px;
}

h2 {
  color: #2c3e50;
  font-size: 24px;
  margin-bottom: 20px;
  border-bottom: 2px solid #ecf0f1;
  padding-bottom: 10px;
}

h3 {
  color: #2c3e50;
  font-size: 22px;
  margin-bottom: 10px;
}

h4 {
  color: #2c3e50;
  font-size: 20px;
  margin-bottom: 10px;
}

/* Form element styles */
input[type="text"],
input[type="number"],
textarea,
select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus,
input[type="number"]:focus,
textarea:focus,
select:focus {
  border-color: #3498db;
  outline: none;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #34495e;
  font-weight: 500;
}

.field-description {
  font-size: 12px;
  color: #7f8c8d;
  margin-top: 5px;
}

/* Add more specific styles for each component as needed */

/* Grid styles for Artists, POIs, and Shows */
.artists-grid,
.pois-grid,
.shows-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.artist-card,
.poi-card,
.show-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.artist-card:hover,
.poi-card:hover,
.show-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.card-image-container {
  width: 100%;
  height: 160px;
  overflow: hidden;
  position: relative;
  background-color: #f0f0f0;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.show-card:hover .card-image {
  transform: scale(1.05);
}

.card-content {
  padding: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.card-title {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.card-subtitle {
  font-size: 14px;
  color: #666;
  margin: 0;
}

.add-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border: 2px dashed #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-height: 300px;
}

.add-card:hover {
  background-color: #e0e0e0;
}

.add-card span {
  font-size: 48px;
  color: #999;
}

.add-card h3 {
  margin-top: 10px;
  font-size: 16px;
  color: #666;
}

/* Specific styles for each component */
.artists-editor,
.pois-editor,
.shows-editor {
  margin-bottom: 40px;
}

.search-bar {
  margin-bottom: 20px;
}

.search-bar input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 90vw;
  width: auto;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-content h3 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  border-bottom: 2px solid #3498db;
  padding-bottom: 10px;
}

.modal-content p {
  color: #333;
}

.modal-content input,
.modal-content textarea,
.modal-content select {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.modal-content label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.modal-content .form-group {
  margin-bottom: 15px;
}

.modal-content .coordinates {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.modal-content .coordinates input {
  flex: 1;
  margin-bottom: 0;
}

.modal-content .date-time-inputs {
  margin-bottom: 15px;
}

.modal-content .date-time-inputs input {
  margin-bottom: 5px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.modal-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.modal-actions button:first-child {
  background-color: #4CAF50;
  color: white;
}

.modal-actions button:first-child:hover {
  background-color: #45a049;
}

.modal-actions button:last-child {
  background-color: #f44336;
  color: white;
}

.modal-actions button:last-child:hover {
  background-color: #d32f2f;
}

.calendar-view {
  overflow-x: auto;
  margin-top: 20px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.calendar-grid {
  display: flex;
  min-width: 800px;
}

.calendar-times {
  width: 60px;
  border-right: 1px solid #ddd;
}

.calendar-hour {
  height: 60px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
  font-size: 12px;
  color: #666;
}

.calendar-stage {
  flex: 1;
  min-width: 200px;
  border-right: 1px solid #ddd;
}

.stage-name {
  padding: 10px;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  background-color: #f5f5f5;
}

.stage-shows {
  position: relative;
  height: 1440px; /* 24 hours * 60px */
}

.calendar-show {
  position: absolute;
  left: 0;
  right: 0;
  background-color: #3498db;
  color: white;
  padding: 5px;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: background-color 0.3s;
}

.calendar-show:hover {
  background-color: #2980b9;
}

.show-info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ImageEditor styles */
.image-editor {
  margin-bottom: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-editor-controls {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.image-type-toggle {
  display: flex;
  background-color: #e9ecef;
  border-radius: 6px;
  overflow: hidden;
}

.image-type-toggle button {
  flex: 1;
  padding: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 14px;
}

.image-type-toggle button.active {
  background-color: #007bff;
  color: white;
}

.api-image-input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.browse-button {
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.browse-button:hover {
  background-color: #218838;
}

.file-name {
  font-size: 14px;
  color: #495057;
}

.url-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
}

.image-preview {
  margin-top: 15px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  overflow: hidden;
  max-width: 300px;
}

.image-preview img {
  display: block;
  width: 100%;
  height: auto;
}

.error-message {
  display: block;
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}

.status-dropdown-container {
  position: relative;
  margin-right: 10px;
}

.status-button {
  padding: 8px 16px;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.status-button.draft {
  background-color: #f39c12;
}

.status-button.published {
  background-color: #27ae60;
}

.status-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.status-dropdown button {
  display: block;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
}

.status-dropdown button:hover {
  background-color: #f5f5f5;
}

/* Add these styles */
.show-card-warning {
  background-color: #ffebee;
  border: 1px solid #ffcdd2;
}

.calendar-show-warning {
  background-color: #ffebee !important;
  border: 1px solid #ffcdd2;
}

.warning-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #f44336;
  font-size: 16px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  padding: 2px;
}

.calendar-show .warning-icon {
  top: 2px;
  right: 2px;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  padding: 1px;
}

.show-card, .calendar-show {
  position: relative;
}

.show-card-warning {
  background-color: #ffebee;
  border: 1px solid #ffcdd2;
  position: relative;
}

.calendar-show-warning {
  background-color: #ffebee !important;
  border: 1px solid #ffcdd2;
  position: relative;
}

/* Add these styles */
.header-actions {
  display: flex;
  align-items: center;
  gap: 15px;
}

.view-toggle-btn {
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.view-toggle-btn:hover {
  background-color: #2980b9;
}

/* Conflict Modal Styles */
.conflict-modal {
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
}

.conflict-modal h2 {
  color: #e74c3c;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
}

.conflict-modal p {
  color: #666;
  margin-bottom: 20px;
}

.diff-container {
  background-color: #f8f9fa;
  border: 1px solid #e1e8ed;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 20px;
}

.diff-container h3 {
  margin-top: 0;
  margin-bottom: 16px;
  color: #2c3e50;
  font-size: 18px;
}

.diff-item {
  margin-bottom: 12px;
  padding: 12px;
  background-color: white;
  border: 1px solid #e1e8ed;
  border-radius: 4px;
  word-break: break-all;
  overflow-wrap: break-word;
}

.diff-path {
  display: block;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 8px;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', monospace;
  font-size: 14px;
  word-break: break-all;
}

.diff-old {
  color: #e74c3c;
  background-color: #fdeaea;
  padding: 4px 8px;
  border-radius: 3px;
  margin-bottom: 4px;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', monospace;
  font-size: 13px;
  white-space: pre-wrap;
  word-break: break-all;
  overflow-wrap: break-word;
}

.diff-new {
  color: #27ae60;
  background-color: #eafaf1;
  padding: 4px 8px;
  border-radius: 3px;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', monospace;
  font-size: 13px;
  white-space: pre-wrap;
  word-break: break-all;
  overflow-wrap: break-word;
}

.conflict-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.conflict-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.conflict-actions button.danger {
  background-color: #e74c3c;
  color: white;
}

.conflict-actions button.danger:hover {
  background-color: #c0392b;
}

.conflict-actions button.secondary {
  background-color: #3498db;
  color: white;
}

.conflict-actions button.secondary:hover {
  background-color: #2980b9;
}

.conflict-actions button.primary {
  background-color: #27ae60;
  color: white;
}

.conflict-actions button.primary:hover {
  background-color: #219a52;
}

.conflict-actions button.tertiary {
  background-color: #95a5a6;
  color: white;
}

.conflict-actions button.tertiary:hover {
  background-color: #7f8c8d;
}

/* Added styles for diff types */
.diff-added .diff-path {
  color: #27ae60;
}

.diff-removed .diff-path {
  color: #e74c3c;
}

.diff-modified .diff-path {
  color: #f39c12;
}

.map-placeholder {
  background-color: #f8f9fa;
  border: 2px dashed #dee2e6;
  border-radius: 8px;
  padding: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.map-placeholder p {
  color: #6c757d;
  margin-bottom: 16px;
}

.map-placeholder button {
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.map-placeholder button:hover {
  background-color: #2980b9;
}

.map-container {
  user-select: none;
  cursor: default;
}

.map-container img {
  pointer-events: none;
}

/* Add these styles to Editor.css */
.music-preview-section {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.input-group {
  margin-bottom: 12px;
}

.input-group input {
  width: 100%;
  padding: 8px 12px;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.input-group input.error {
  border-color: #ff4444;
  border-width: 1px;
  border-style: solid;
  text-align: left;
}

.error-message {
  color: #ff4444;
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: left;
  white-space: pre-line;
}

/* Add specific styles for music preview inputs */
.music-preview-section .input-group {
  margin-bottom: 12px;
}

.music-preview-section .input-group input {
  width: 100%;
  padding: 8px 12px;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.music-preview-section .input-group input.error {
  border-color: #ff4444;
}

.music-preview-section .error-message {
  color: #ff4444;
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: left;
  white-space: pre-line;
}

/* Add these styles at the end of the file */

.notifications-editor {
  max-width: 600px;
  margin: 0 auto;
}

.notifications-editor .form-group {
  margin-bottom: 20px;
}

.notifications-editor label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.notifications-editor select,
.notifications-editor input,
.notifications-editor textarea {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.notifications-editor textarea {
  resize: vertical;
  min-height: 100px;
}

.notifications-editor .send-button {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.notifications-editor .send-button:hover {
  background-color: #2980b9;
}

.success-alert {
  background-color: #d4edda;
  color: #155724;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

.notification-preview {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 4px;
  margin: 15px 0;
}

.notification-preview p {
  margin: 8px 0;
}

/* Add these styles */
.coordinates-button {
  margin: 15px 0;
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.coordinates-button:hover {
  background-color: #2980b9;
}

.map-modal {
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
}

.map-overlay-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  overflow: hidden;
}

.map-controls {
  display: flex;
  gap: 16px;
  margin-bottom: 8px;
  padding: 0;
  width: 100%;
}

.custom-zoom-control,
.custom-transparency-control {
  background: white;
  padding: 12px 16px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}

.control-title {
  color: #666;
  font-size: 14px;
  margin-bottom: 4px;
}

.control-content {
  display: flex;
  align-items: center;
  gap: 12px;
  height: 32px;
}

.custom-zoom-control button {
  width: 32px;
  height: 32px;
  padding: 0;
  border: 1px solid #e9ecef;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #666;
  transition: all 0.2s;
  flex-shrink: 0;
}

.custom-zoom-control input[type="range"],
.custom-transparency-control input[type="range"] {
  flex: 1;
  margin: 0;
  padding: 0;
  height: 4px;
  align-self: center;
}

.map-overlay-container {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.map-overlay {
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow: hidden;
}

/* Update slider styles */
input[type="range"] {
  -webkit-appearance: none;
  background: transparent;
  position: relative;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  background: #e9ecef;
  border-radius: 2px;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #3498db;
  cursor: pointer;
  border: none;
  margin-top: -6px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4px;
  background: #e9ecef;
  border-radius: 2px;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #3498db;
  cursor: pointer;
  border: none;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}

.zoom-value,
.opacity-value {
  min-width: 48px;
  text-align: center;
  font-family: monospace;
  font-size: 14px;
  color: #666;
  flex-shrink: 0;
  line-height: 32px;
}

/* Add specific styles for the map modal */
.modal-content.map-modal {
  width: auto;
  max-width: 90vw;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
}

.modal-content.map-modal .map-overlay-container {
  width: auto;
  min-width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content.map-modal .map-overlay {
  flex: 0 0 auto;
  width: auto;
  height: auto;
}

.unsaved-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.unsaved-modal {
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.unsaved-modal h2 {
  margin: 0 0 16px 0;
  color: #333;
}

.unsaved-modal p {
  margin: 0 0 24px 0;
  color: #666;
}

.unsaved-modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.unsaved-modal-buttons button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
}

.unsaved-modal-buttons .cancel {
  background-color: #e0e0e0;
  color: #666;
}

.unsaved-modal-buttons .discard {
  background-color: #ff4444;
  color: white;
}

.unsaved-modal-buttons .save {
  background-color: #4CAF50;
  color: white;
}

.unsaved-modal-buttons button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Dropdown styles for notifications editor */
.custom-dropdown {
  position: relative;
  width: 100%;
}

.dropdown-header {
  padding: 10px 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.dropdown-header:hover {
  border-color: #b3b3b3;
}

.dropdown-header:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

.dropdown-arrow {
  font-size: 12px;
  color: #666;
  transition: transform 0.2s;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;

  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
}

.dropdown-content input {
  margin-bottom: 0px;
}

.filter-container {
  position: relative;
}

.recipient-filter {
  width: 100%;
  padding: 12px;
  border: none;
  font-size: 14px;
  background: white;
  box-sizing: border-box;
  color: #000;
  border-bottom: 1px solid #eee;
}

.recipient-filter:focus {
  outline: none;
  background-color: #fff;
}


/* Remove the clear button styles as we're removing it from the UI */
.clear-filter {
  display: none;
}

.dropdown-section-header {
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 600;
  color: #666;
  background-color: #f8f9fa;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-count {
  font-size: 11px;
  color: #999;
  background-color: #eee;
  padding: 2px 6px;
  border-radius: 10px;
}

.dropdown-options {
  max-height: 300px;
  overflow-y: auto;
  flex-grow: 1;
}

.dropdown-option {
  padding: 10px 14px 10px 24px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropdown-option:hover {
  background-color: #f5f5f5;
}

.dropdown-option.selected {
  background-color: #e3f2fd;
  color: #1976d2;
  font-weight: 500;
}

.dropdown-no-results {
  padding: 16px;
  text-align: center;
  color: #666;
  font-style: italic;
  background-color: #f8f9fa;
}

.create-topic-button {
  margin-top: 8px;
  padding: 8px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.create-topic-button:hover {
  background: var(--primary-color-dark);
}

.dropdown-no-results {
  padding: 12px;
  color: var(--text-color-secondary);
}
