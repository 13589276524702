body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282c34;
  color: #333;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.Login, .VersionList, .CreateFestival {
  width: 100%;
  max-width: 400px;
  padding: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.form-group {
  margin-bottom: 1rem;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  text-align: left;
}

input {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #61dafb;
  background-color: #3a3f4b;
  color: white;
}

button {
  background-color: #61dafb;
  color: #282c34;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1rem;
}

button:hover {
  background-color: #4fa8d5;
}

.create-festival-btn {
  background-color: #4CAF50;
  color: white;
}

.create-festival-btn:hover {
  background-color: #45a049;
}

.back-btn {
  background-color: #f44336;
  color: white;
}

.back-btn:hover {
  background-color: #d32f2f;
}

.error {
  color: #ff6b6b;
  margin-bottom: 1rem;
}

.version-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.version-list li {
  background-color: #3a3f4b;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
}

.logout-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #f44336;
  color: white;
}

.logout-btn:hover {
  background-color: #d32f2f;
}
