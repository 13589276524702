/* News Editor Styles */
.news-editor .add-news-button {
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .news-editor .add-news-button:hover {
    background-color: #45a049;
  }
  
  .news-editor .news-list {
    list-style-type: none;
    padding: 0;
  }
  
  .news-editor .news-item {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 15px;
    overflow: hidden;
  }
  
  .news-editor .news-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  .news-editor .news-item-header h4 {
    margin: 0;
    font-size: 18px;
    color: #333;
  }
  
  .news-editor .news-item-header span {
    font-size: 14px;
    color: #666;
  }
  
  .news-editor .news-item-edit {
    padding: 15px;
  }
  
  .news-editor .news-item-edit input,
  .news-editor .news-item-edit textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .news-editor .news-item-edit textarea {
    min-height: 100px;
  }
  
  .news-editor .news-item-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;
  }
  
  .news-editor .news-item-actions button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .news-editor .news-item-actions button:first-child {
    background-color: #4CAF50;
    color: white;
  }
  
  .news-editor .news-item-actions button:first-child:hover {
    background-color: #45a049;
  }
  
  .news-editor .news-item-actions button:last-child {
    background-color: #f44336;
    color: white;
  }
  
  .news-editor .news-item-actions button:last-child:hover {
    background-color: #d32f2f;
  }

.news-item-preview {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.news-thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}

.news-thumbnail-placeholder {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border-radius: 4px;
  color: #666;
}

.news-thumbnail-placeholder svg {
  width: 24px;
  height: 24px;
}

.news-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
}

.news-item-header:hover {
  background-color: #f5f5f5;
}