.editor-menu {
  width: 200px;
  min-width: 200px;
  flex-shrink: 0;
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 40px 0;
}

.editor-section {
  margin-bottom: 20px;
  margin-left: 12px;
}

.section-title {
  font-size: 14px;
  text-transform: uppercase;
  color: #95a5a6;
  padding: 0;
  margin: 0 0 10px 8px;
  font-weight: 600;
  border-bottom: 0px;
}

.editor-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.editor-menu li {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.editor-menu li:hover {
  background-color: #34495e;
}

.editor-menu li.active {
  background-color: #3498db;
} 