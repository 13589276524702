.carpools-editor {
  padding: 20px;
}

.carpools-table-container {
  overflow-x: auto;
  margin-top: 20px;
}

.carpools-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.carpools-table th,
.carpools-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.carpools-table th {
  background-color: #f5f5f5;
  font-weight: 600;
  color: #333;
}

.carpools-table tr:hover {
  background-color: #f8f9fa;
}

.carpools-table td {
  color: #666;
  font-size: 14px;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.delete-button:hover {
  background-color: #c82333;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h3 {
  margin: 0;
  color: #333;
}

.modal-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.modal-error {
  color: #dc3545;
  margin-bottom: 16px;
  padding: 8px;
  background-color: #f8d7da;
  border-radius: 4px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.modal-delete {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.modal-delete:hover:not(:disabled) {
  background-color: #c82333;
}

.modal-cancel {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.modal-cancel:hover:not(:disabled) {
  background-color: #5a6268;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
} 