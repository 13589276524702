.festivals-list-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  padding: 20px;
}

.festivals-list-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
}

.festivals-list-content h1 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.festivals-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.festival-item {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.festival-item:hover {
  background-color: #f5f5f5;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.festival-item h3 {
  margin: 0 0 10px 0;
  color: #333;
}

.festival-item p {
  margin: 0;
  color: #666;
}

.loader {
  text-align: center;
  padding: 20px;
  color: #666;
}

.error-message {
  color: #f44336;
  text-align: center;
  margin: 10px 0;
} 