.advertisements-editor {
  padding: 20px;
}

.add-ad-button {
  margin-bottom: 20px;
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-ad-button:hover {
  background-color: #45a049;
}

.advertisements-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.advertisement-item {
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.advertisement-preview {
  padding: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  transition: background-color 0.2s;
}

.advertisement-preview:hover {
  background-color: #f5f5f5;
}

.advertisement-info {
  flex: 1;
}

.advertisement-info h3 {
  margin: 0 0 8px 0;
  font-size: 16px;
  color: #333;
}

.advertisement-description {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.advertisement-stats {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  font-size: 14px;
  white-space: nowrap;
}

.weight-display {
  color: #666;
}

.chance-display {
  color: #2196f3;
  font-weight: 500;
}

.advertisement-edit-form {
  padding: 16px;
  border-top: 1px solid #e0e0e0;
  background-color: #f8f9fa;
}

.weight-input {
  margin: 12px 0;
}

.weight-input label {
  display: block;
  margin-bottom: 4px;
  color: #666;
}

.weight-input input {
  width: 120px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.advertisement-actions {
  display: flex;
  gap: 8px;
  margin-top: 16px;
}

.advertisement-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.advertisement-actions button:first-child {
  background-color: #4CAF50;
  color: white;
}

.advertisement-actions button:first-child:hover {
  background-color: #43a047;
}

.advertisement-actions button:nth-child(2) {
  background-color: #9e9e9e;
  color: white;
}

.advertisement-actions button:nth-child(2):hover {
  background-color: #757575;
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.delete-button:hover {
  background-color: #e53935;
}

.weight-summary {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 24px;
  border: 1px solid #e0e0e0;
}

.total-weight {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.weight-warning {
  color: #f44336;
}

.weight-warning-text {
  font-size: 14px;
  background-color: #ffebee;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ffcdd2;
}

.weight-explanation {
  color: #666;
}

.chances-header {
  font-weight: 500;
  color: #333;
  margin-bottom: 12px;
}

.chances-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.chance-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
}

.chance-item.no-display {
  background-color: #f8f9fa;
  border-style: dashed;
}

.chance-title {
  font-weight: 500;
  color: #333;
}

.chance-value {
  font-family: monospace;
  font-size: 14px;
  color: #2196f3;
  font-weight: 500;
  padding: 2px 8px;
  background-color: #e3f2fd;
  border-radius: 4px;
}

.chance-item.no-display .chance-value {
  color: #757575;
  background-color: #eeeeee;
}

.link-input {
  margin: 12px 0;
}

.link-input label {
  display: block;
  margin-bottom: 4px;
  color: #666;
}

.link-input input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.link-input input.error {
  border-color: #f44336;
  background-color: #fff8f8;
}

.link-error {
  color: #f44336;
  font-size: 12px;
  margin-top: 4px;
}

.advertisement-link {
  display: inline-block;
  color: #2196f3;
  font-size: 14px;
  text-decoration: none;
  margin-top: 8px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.advertisement-link:hover {
  text-decoration: underline;
}

.advertisement-images {
  display: flex;
  gap: 24px;
  margin: 20px 0;
}

.image-editor-container {
  flex: 1;
  min-width: 0;
}

.image-editor-description {
  margin-bottom: 12px;
}

.image-editor-description h4 {
  font-size: 16px;
  color: #333;
  margin: 0 0 4px 0;
}

.image-editor-description p {
  font-size: 14px;
  color: #666;
  margin: 0;
  line-height: 1.4;
}

@media (max-width: 768px) {
  .advertisement-images {
    flex-direction: column;
    gap: 32px;
  }
} 