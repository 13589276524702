.image-editor {
  position: relative;
  margin-bottom: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
}

.image-preview {
  position: relative;
  width: 100%;
  min-height: 300px;
  max-width: 600px;
  background-color: #e9ecef;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.image-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.image-container img {
  width: 100%;
  height: 100%;
}

.image-cover {
  object-fit: cover;
}

.image-contain {
  object-fit: contain;
}

.editor-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background: linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.image-type-toggle {
  display: flex;
  gap: 8px;
  justify-content: center;
}

.image-type-toggle button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.15);
  color: gray;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  backdrop-filter: blur(4px);
  margin-top: 0px;
  min-width: 100px;
}

.image-type-toggle button:hover {
  background-color: rgba(255, 255, 255, 0.25);
  color: rgba(255, 255, 255, 0.9);
}

.image-type-toggle button.active {
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
}

.image-type-toggle .icon {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  flex-shrink: 0;
  position: relative;
  top: -1px;
}

.browse-button {
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #333;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  width: auto;
  margin: 0 auto;
}

.browse-button:hover {
  background-color: white;
  transform: translateY(-1px);
}

.url-input {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px 16px;
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  font-size: 14px;
  color: white;
  backdrop-filter: blur(4px);
  transition: all 0.2s ease;
}

.url-input:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 0.25);
  border-color: rgba(255, 255, 255, 0.3);
}

.url-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.image-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  color: #6c757d;
  text-align: center;
  width: 100%;
  height: 100%;
}

.image-placeholder svg {
  width: 48px;
  height: 48px;
  margin-bottom: 16px;
  color: #adb5bd;
}

.image-placeholder p {
  margin: 0;
  font-size: 14px;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px 16px;
  background-color: rgba(220, 53, 69, 0.9);
  color: white;
  font-size: 14px;
  text-align: center;
}

.image-editors-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);  /* 3 columns */
  gap: 20px;
  margin: 20px 0;
}

.image-title {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
  text-align: center;
}

.image-caption {
  font-size: 12px;
  color: #666;
  margin-top: 8px;
  text-align: center;
  font-style: italic;
}

