.version-history {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.version-history h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.error-message {
  color: #f44336;
  text-align: center;
  margin-bottom: 15px;
}

.empty-state {
  text-align: center;
  padding: 40px;
  color: #7f8c8d;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.revision-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.revision-item {
  border-bottom: 1px solid #e0e0e0;
}

.revision-item:last-child {
  border-bottom: none;
}

.revision-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s;
}

.revision-row:hover {
  background-color: #f5f5f5;
}

.revision-user-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.user-email {
  font-weight: bold;
  color: #333;
  font-size: 14px;
}

.revision-date {
  font-size: 14px;
  color: #666;
}

.revert-button {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.revert-button:hover {
  background-color: #2980b9;
}

.diff-content {
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 16px;
  margin: 16px 0;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #e1e8ed;
}

.diff-content pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', monospace;
  font-size: 13px;
  line-height: 1.5;
  color: #2c3e50;
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.modal-content h3 {
  margin: 0 0 20px 0;
  color: #333;
  font-size: 20px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.primary-button, .secondary-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.primary-button {
  background-color: #3498db;
  color: white;
}

.primary-button:hover {
  background-color: #2980b9;
}

.secondary-button {
  background-color: #95a5a6;
  color: white;
}

.secondary-button:hover {
  background-color: #7f8c8d;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.diff-summary {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.diff-item {
  display: grid;
  grid-template-columns: 100px 1fr 120px;
  gap: 12px;
  align-items: center;
  padding: 8px 12px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e1e8ed;
}

.diff-section {
  font-weight: 600;
  color: #2c3e50;
  text-transform: capitalize;
}

.diff-field {
  color: #34495e;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', monospace;
  font-size: 13px;
}

.diff-change {
  color: #7f8c8d;
  font-size: 13px;
  text-align: right;
}

.diff-raw {
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', monospace;
  font-size: 13px;
  line-height: 1.5;
  color: #2c3e50;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.diff-error {
  color: #e74c3c;
  padding: 12px;
  background-color: #fdeaea;
  border-radius: 4px;
  margin-bottom: 12px;
}

.diff-empty {
  color: #7f8c8d;
  text-align: center;
  padding: 12px;
}
  